<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="12"
      >
        <b-row class="breadcrumbs-top">
          <b-col
            class="content-header-left mb-2"
            cols="12"
            md="12"
          >
            <b-row class="breadcrumbs-top">
              <b-col cols="12">
                <h1 class="custom-header-title">
                  Editing {{ agent.code }} - {{ agent.name }}
                </h1>
                <p class="log_info">
                  Created by {{ agent.createdBy || '-' }} on {{ agent.createdAt ? dateFormatWithTime(agent.createdAt) : '-' }}<br>Last updated on {{ agent.updatedAt ? dateFormatWithTime(agent.updatedAt) : '-' }}
                </p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <b-form @submit.prevent="submitAgentSaveForm">
      <validation-observer
        ref="agentEditForm"
      >
        <b-card
          v-show="canViewThisAction('update', 'ServiceForm') || canViewThisAction('update-applicant', 'ServiceForm')"
          ref="applicant_detail"
          header-tag="header"
        >
          <template #header>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              class="primary-button-with-background mr-3"
            >
              <feather-icon
                icon="GridIcon"
                class=""
                size="20"
              />
            </b-button>
            <h3 class="align-middle mr-auto">
              Profile Details
            </h3>
          </template>
          <b-row>
            <b-col cols="md-12">
              <b-media class="mb-2 agent__media">
                <template #aside>
                  <b-avatar
                    ref="previewEl"
                    :src="avatarSrc"
                    size="120"
                  />
                </template>
                <div class="d-flex flex-wrap mb-1">
                  <b-button
                    variant="primary"
                    @click="$refs.refInputEl.$el.childNodes[0].click()"
                  >
                    <b-form-file
                      ref="refInputEl"
                      v-model="avatar"
                      type="file"
                      class="d-none"
                      accept="image/*"
                      @input="setImage"
                    />
                    <span class="d-none d-sm-inline">Update</span>
                    <feather-icon
                      icon="EditIcon"
                      class="d-inline d-sm-none"
                    />
                  </b-button>
                  <b-button
                    variant="outline-primary"
                    class="ml-1"
                    @click="avatarSrc = null"
                  >
                    <span class="d-none d-sm-inline">Reset</span>
                    <feather-icon
                      icon="TrashIcon"
                      class="d-inline d-sm-none"
                    />
                  </b-button>
                </div>
                <p>Allowed JPEG, GIF or PNG. Max size of 800kB</p>
              </b-media>
            </b-col>
            <b-col cols="md-6">
              <b-form-group
                label="Agent Name"
                label-for="h-agent-name"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Agent Name"
                  vid="agentName"
                  rules=""
                >
                  <b-form-input
                    id="h-agent-name"
                    v-model="agentName"
                    placeholder="Agent Name"
                    :state="(errors.length > 0 || agentNameValidation) ? false : null"
                    name="agentName"
                    readonly
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="agentNameValidation"
                    class="text-danger"
                  >
                    {{ agentNameError }}
                  </small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Card Display Name"
                label-for="h-card-display-name"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Card Display Name"
                  vid="cardDisplayName"
                  rules=""
                >
                  <b-form-input
                    id="h-card-display-name"
                    v-model="cardDisplayName"
                    placeholder="Card Display Name"
                    :state="(errors.length > 0 || cardDisplayNameValidation) ? false : null"
                    name="cardDisplayName"
                    readonly
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="cardDisplayNameValidation"
                    class="text-danger"
                  >
                    {{ cardDisplayNameError }}
                  </small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Agent Code"
                label-for="h-agent-code"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Agent Code"
                  vid="agentCode"
                  rules=""
                >
                  <b-form-input
                    id="h-agent-code"
                    v-model="agentCode"
                    placeholder="Agent Code"
                    :state="(errors.length > 0 || agentCodeValidation) ? false : null"
                    name="agentCode"
                    readonly
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="agentCodeValidation"
                    class="text-danger"
                  >
                    {{ agentCodeError }}
                  </small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Agency"
                label-for="h-agency"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Agency"
                  vid="agency"
                  rules=""
                >
                  <b-form-input
                    id="h-agency"
                    v-model="agency"
                    placeholder="Agency"
                    :state="(errors.length > 0 || agencyValidation) ? false : null"
                    name="agency"
                    readonly
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="agencyValidation"
                    class="text-danger"
                  >
                    {{ agencyError }}
                  </small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Country Code"
                label-for="h-country-code"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Country Code"
                  vid="countryCode"
                  rules=""
                >
                  <b-form-input
                    id="h-country-code"
                    v-model="countryCode"
                    placeholder="Country Code"
                    :state="(errors.length > 0 || countryCodeValidation) ? false : null"
                    name="countryCode"
                    readonly
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="countryCodeValidation"
                    class="text-danger"
                  >
                    {{ countryCodeError }}
                  </small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="State Code"
                label-for="h-state-code"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="State Code"
                  vid="stateCode"
                  rules=""
                >
                  <b-form-input
                    id="h-state-code"
                    v-model="stateCode"
                    placeholder="State Code"
                    :state="(errors.length > 0 || stateCodeValidation) ? false : null"
                    name="stateCode"
                    readonly
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="stateCodeValidation"
                    class="text-danger"
                  >
                    {{ stateCodeError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="md-6"
            >
              <validation-provider
                #default="{ errors }"
                name="Join Date"
                vid="joinDate"
                rules=""
              >
                <b-form-group
                  label="Join Date"
                  label-for="h-join-date"
                  label-cols-md="4"
                >
                  <b-form-input
                    id="h-join-date"
                    v-model="joinDate"
                    placeholder="Join Date"
                    :state="(errors.length > 0 || joinDateValidation) ? false : null"
                    name="joinDate"
                    readonly
                    @input="joinDateValidation == true ? joinDateValidation = false : null"
                  />

                  <small
                    v-if="joinDateValidation"
                    class="text-danger"
                  >
                    {{ joinDateError }}
                  </small>
                </b-form-group>
              </validation-provider>
              <validation-provider
                #default="{ errors }"
                name="Expiry Date"
                vid="expiryDate"
                rules=""
              >
                <b-form-group
                  label="Expiry Date"
                  label-for="h-expiry-date"
                  label-cols-md="4"
                >
                  <b-form-input
                    id="h-expiry-date"
                    v-model="expiryDate"
                    placeholder="Expiry Date"
                    :state="(errors.length > 0 || expiryDateValidation) ? false : null"
                    name="expiryDate"
                    readonly
                    @input="expiryDateValidation == true ? expiryDateValidation = false : null"
                  />
                  <small
                    v-if="expiryDateValidation"
                    class="text-danger"
                  >
                    {{ expiryDateError }}
                  </small>
                </b-form-group>
              </validation-provider>
              <validation-provider
                #default="{ errors }"
                name="Confirm Date"
                vid="confirmDate"
                rules=""
              >
                <b-form-group
                  label="Confirm Date"
                  label-for="h-confirm-date"
                  label-cols-md="4"
                >
                  <b-form-input
                    id="h-confirm-date"
                    v-model="confirmDate"
                    placeholder="Join Date"
                    :state="(errors.length > 0 || confirmDateValidation) ? false : null"
                    name="confirmDate"
                    readonly
                    @input="confirmDateValidation == true ? confirmDateValidation = false : null"
                  />
                  <small
                    v-if="confirmDateValidation"
                    class="text-danger"
                  >
                    {{ confirmDateError }}
                  </small>
                </b-form-group>
              </validation-provider>
              <validation-provider
                #default="{ errors }"
                name="Status"
                vid="status"
                rules=""
              >
                <b-form-group
                  label="Status"
                  label-for="h-status"
                  label-cols-md="4"
                  :state="(errors.length > 0 || statusValidation) ? false : null"
                >
                  <b-form-input
                    id="h-status"
                    v-model="status"
                    placeholder="Status"
                    :state="(errors.length > 0 || statusValidation) ? false : null"
                    name="Status"
                    readonly
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="statusValidation"
                    class="text-danger"
                  >
                    {{ statusError }}
                  </small>
                </b-form-group>
              </validation-provider>
              <b-form-group
                label="Remarks"
                label-for="h-remarks"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Remarks"
                  vid="remarks"
                  rules=""
                >
                  <b-form-textarea
                    id="h-remarks"
                    v-model="remarks"
                    placeholder="Remarks"
                    :state="(errors.length > 0 || remarksValidation) ? false : null"
                    readonly
                    name="remarks"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="remarksValidation"
                    class="text-danger"
                  >
                    {{ remarksError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>

        <b-card
          ref="deceased_detail"
          header-tag="header"
        >
          <template #header>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              class="primary-button-with-background mr-3"
            >
              <feather-icon
                icon="GridIcon"
                class=""
                size="20"
              />
            </b-button>
            <h3 class="align-middle mr-auto">
              Personal Information
            </h3>
          </template>
          <b-row>
            <b-col cols="md-6">
              <b-form-group
                label="NRIC"
                label-for="h-NRIC"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="NRIC"
                  vid="NRIC"
                  rules=""
                >
                  <b-form-input
                    id="h-NRIC"
                    v-model="NRIC"
                    placeholder="NRIC"
                    :state="(errors.length > 0 || NRICValidation) ? false : null"
                    name="NRIC"
                    readonly
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="NRICValidation"
                    class="text-danger"
                  >
                    {{ NRICError }}
                  </small>
                </validation-provider>
              </b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Sex"
                vid="sex"
                rules=""
              >
                <b-form-group
                  label="Sex"
                  label-for="h-sex"
                  label-cols-md="4"
                  :state="(errors.length > 0 || sexValidation) ? false : null"
                >
                  <b-form-input
                    id="h-sex"
                    v-model="sex"
                    placeholder="Sex"
                    :state="(errors.length > 0 || sexValidation) ? false : null"
                    name="Status"
                    readonly
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="sexValidation"
                    class="text-danger"
                  >
                    {{ sexError }}
                  </small>
                </b-form-group>
              </validation-provider>
              <b-form-group
                label="Race"
                label-for="h-race"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Race"
                  vid="race"
                  rules=""
                >
                  <b-form-input
                    id="h-race"
                    v-model="race"
                    placeholder="Race"
                    :state="(errors.length > 0 || raceValidation) ? false : null"
                    name="race"
                    readonly
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="raceValidation"
                    class="text-danger"
                  >
                    {{ raceError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="md-6">
              <validation-provider
                #default="{ errors }"
                name="Marital"
                vid="marital"
                rules=""
              >
                <b-form-group
                  label="Marital"
                  label-for="h-marital"
                  label-cols-md="4"
                  :state="(errors.length > 0 || maritalValidation) ? false : null"
                >
                  <b-form-input
                    id="h-marital"
                    v-model="marital"
                    placeholder="Marital"
                    :state="(errors.length > 0 || maritalValidation) ? false : null"
                    name="Marital"
                    readonly
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="maritalValidation"
                    class="text-danger"
                  >
                    {{ maritalError }}
                  </small>
                </b-form-group>
              </validation-provider>
              <validation-provider
                #default="{ errors }"
                name="Date of Birth"
                vid="dob"
                rules=""
              >
                <b-form-group
                  label="Date of Birth"
                  label-for="h-dob"
                  label-cols-md="4"
                >
                  <b-form-input
                    id="h-dob"
                    v-model="dob"
                    placeholder="Date of Birth"
                    :state="(errors.length > 0 || dobValidation) ? false : null"
                    name="dob"
                    readonly
                    @input="dobValidation == true ? dobValidation = false : null"
                  />
                  <small
                    v-if="dobValidation"
                    class="text-danger"
                  >
                    {{ dobError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-card>

        <b-card
          ref="agent_detail"
          header-tag="header"
        >
          <template #header>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              class="primary-button-with-background mr-3"
            >
              <feather-icon
                icon="GridIcon"
                class=""
                size="20"
              />
            </b-button>
            <h3 class="align-middle mr-auto">
              Correspondence Contact
            </h3>
          </template>
          <b-row>
            <b-col cols="md-6">
              <b-form-group
                label="Primary Contact"
                label-for="h-primary-contact"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Primary Contact"
                  vid="primaryContact"
                  rules=""
                >
                  <b-form-input
                    id="h-primary-contact"
                    v-model="primaryContact"
                    placeholder="Primary Contact"
                    :state="(errors.length > 0 || primaryContactValidation) ? false : null"
                    name="primaryContact"
                    readonly
                    @input="primaryContactValidation == true ? primaryContactValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="primaryContactValidation"
                    class="text-danger"
                  >
                    {{ primaryContactError }}
                  </small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Secondary Contact"
                label-for="h-secondary-contact"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Secondary Contact"
                  vid="secondaryContact"
                  rules=""
                >
                  <b-form-input
                    id="h-secondary-contact"
                    v-model="secondaryContact"
                    placeholder="Secondary Contact"
                    :state="(errors.length > 0 || secondaryContactValidation) ? false : null"
                    name="secondaryContact"
                    readonly
                    @input="secondaryContactValidation == true ? secondaryContactValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="secondaryContactValidation"
                    class="text-danger"
                  >
                    {{ secondaryContactError }}
                  </small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Email Address"
                label-for="h-email-address"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email Address"
                  vid="emailAddress"
                  rules=""
                >
                  <b-form-input
                    id="h-email-address"
                    v-model="emailAddress"
                    placeholder="Email Address"
                    :state="(errors.length > 0 || emailAddressValidation) ? false : null"
                    name="emailAddress"
                    readonly
                    @input="emailAddressValidation == true ? emailAddressValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="emailAddressValidation"
                    class="text-danger"
                  >
                    {{ emailAddressError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="md-6">
              <b-form-group
                label="Address 1"
                label-for="h-address1"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Address 1"
                  vid="address1"
                  rules=""
                >
                  <b-form-input
                    id="h-address1"
                    v-model="address1"
                    placeholder="Address 1"
                    :state="(errors.length > 0 || address1Validation) ? false : null"
                    name="address1"
                    readonly
                    @input="address1Validation == true ? address1Validation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="address1Validation"
                    class="text-danger"
                  >
                    {{ address1Error }}
                  </small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Address 2"
                label-for="h-address2"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Address 2"
                  vid="address2"
                  rules=""
                >
                  <b-form-input
                    id="h-address2"
                    v-model="address2"
                    placeholder="Address 2"
                    :state="(errors.length > 0 || address2Validation) ? false : null"
                    readonly
                    name="address2"
                    @input="address2Validation == true ? address2Validation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="address2Validation"
                    class="text-danger"
                  >
                    {{ address2Error }}
                  </small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Address 3"
                label-for="h-address3"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Address 3"
                  vid="address3"
                  rules=""
                >
                  <b-form-input
                    id="h-address3"
                    v-model="address3"
                    placeholder="Address 3"
                    :state="(errors.length > 0 || address3Validation) ? false : null"
                    readonly
                    name="address3"
                    @input="address3Validation == true ? address3Validation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="address3Validation"
                    class="text-danger"
                  >
                    {{ address3Error }}
                  </small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Post Code"
                label-for="h-post-code"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Post Code"
                  vid="postCode"
                  rules=""
                >
                  <b-form-input
                    id="h-post-code"
                    v-model="postCode"
                    placeholder="Post Code"
                    :state="(errors.length > 0 || postCodeValidation) ? false : null"
                    readonly
                    name="postCode"
                    @input="postCodeValidation == true ? postCodeValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="postCodeValidation"
                    class="text-danger"
                  >
                    {{ postCodeError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>

        <b-card
          ref="agent_detail"
          header-tag="header"
        >
          <template #header>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              class="primary-button-with-background mr-3"
            >
              <feather-icon
                icon="GridIcon"
                class=""
                size="20"
              />
            </b-button>
            <h3 class="align-middle mr-auto">
              Emergency Contact
            </h3>
          </template>
          <b-row>
            <b-col cols="md-6">
              <b-form-group
                label="Emergency Contact Person"
                label-for="h-e-contact-person"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Emergency Contact Person"
                  vid="eContactPerson"
                  rules=""
                >
                  <b-form-input
                    id="h-e-contact-person"
                    v-model="eContactPerson"
                    placeholder="Emergency Contact Person"
                    :state="(errors.length > 0 || eContactPersonValidation) ? false : null"
                    name="eContactPerson"
                    readonly
                    @input="eContactPersonValidation == true ? eContactPersonValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="eContactPersonValidation"
                    class="text-danger"
                  >
                    {{ eContactPersonError }}
                  </small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Mobile No."
                label-for="h-mobile-no"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Mobile No."
                  vid="mobileNo"
                  rules=""
                >
                  <b-form-input
                    id="h-mobile-no"
                    v-model="mobileNo"
                    placeholder="Mobile No."
                    :state="(errors.length > 0 || mobileNoValidation) ? false : null"
                    name="mobileNo"
                    readonly
                    @input="mobileNoValidation == true ? mobileNoValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="mobileNoValidation"
                    class="text-danger"
                  >
                    {{ mobileNoError }}
                  </small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Relationship*"
                label-for="h-relationship"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Relationship"
                  vid="relationship"
                  rules=""
                >
                  <b-form-input
                    id="h-relationship"
                    v-model="relationship"
                    placeholder="Relationship"
                    :state="(errors.length > 0 || relationshipValidation) ? false : null"
                    name="relationship"
                    readonly
                    @input="relationshipValidation == true ? relationshipValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="relationshipValidation"
                    class="text-danger"
                  >
                    {{ relationshipError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>

        <b-card
          ref="agent_detail"
          header-tag="header"
        >
          <template #header>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              class="primary-button-with-background mr-3"
            >
              <feather-icon
                icon="GridIcon"
                class=""
                size="20"
              />
            </b-button>
            <h3 class="align-middle mr-auto">
              Bank Details
            </h3>
          </template>
          <b-row>
            <b-col cols="md-6">
              <b-form-group
                label="Bank Code*"
                label-for="h-bank-code"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Bank Code"
                  vid="bankCode"
                  rules=""
                >
                  <b-form-input
                    id="h-bank-code"
                    v-model="bankCode"
                    placeholder="Bank Code"
                    :state="(errors.length > 0 || bankCodeValidation) ? false : null"
                    name="bankCode"
                    readonly
                    @input="bankCodeValidation == true ? bankCodeValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="bankCodeValidation"
                    class="text-danger"
                  >
                    {{ bankCodeError }}
                  </small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Bank Branch*"
                label-for="h-bank-branch"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Bank Branch"
                  vid="bankBranch"
                  rules=""
                >
                  <b-form-input
                    id="h-bank-branch"
                    v-model="bankBranch"
                    placeholder="Bank Branch"
                    :state="(errors.length > 0 || bankBranchValidation) ? false : null"
                    readonly
                    name="bankBranch"
                    @input="bankBranchValidation == true ? bankBranchValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="bankBranchValidation"
                    class="text-danger"
                  >
                    {{ bankBranchError }}
                  </small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Bank Account No.*"
                label-for="h-bank-account-no"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Bank Account No."
                  vid="bankAccountNo"
                  rules=""
                >
                  <b-form-input
                    id="h-bank-account-no"
                    v-model="bankAccountNo"
                    placeholder="Bank Account No."
                    :state="(errors.length > 0 || bankAccountNoValidation) ? false : null"
                    readonly
                    name="bankAccountNo"
                    @input="bankAccountNoValidation == true ? bankAccountNoValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="bankAccountNoValidation"
                    class="text-danger"
                  >
                    {{ bankAccountNoError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>

        <b-card
          ref="attachment"
          header-tag="header"
        >
          <template #header>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              class="primary-button-with-background mr-3"
            >
              <feather-icon
                icon="GridIcon"
                class=""
                size="20"
              />
            </b-button>
            <h3 class="align-middle mr-auto">
              Supporting Document(s)
            </h3>
          </template>
          <b-row>
            <b-col
              cols="12"
              :class="{'disable_dropzone': (!canViewThisAction('update', 'ServiceForm') && !canViewThisAction('update-attachment', 'ServiceForm')) }"
            >
              <vue-dropzone
                id="event-images"
                ref="myVueDropzone"
                disabled
                :options="dropzoneOptions"
                :use-custom-slot="true"
                @vdropzone-files-added="processFile"
                @vdropzone-removed-file="fileRemoved"
                @vdropzone-error="handleError"
              >
                <div class="dropzone-custom-content">
                  <h3 class="dropzone-custom-title">
                    Drop file here or click to upload
                  </h3>
                  <div class="subtitle">
                    Images should not bigger than 5 mb. JPEG or PDF only.
                  </div>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="button"
                    variant="primary"
                    class="mt-2"
                  >
                    Upload file
                  </b-button>
                </div>
              </vue-dropzone>
            </b-col>
          </b-row>
        </b-card>

        <b-nav class="wrap-border save-nav">
          <li
            class="nav-item ml-auto"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              :to="{ name: 'agency-agents-show', params: { id: $route.params.id } }"
            >
              Cancel
            </b-button>
          </li>
          <li
            class="nav-item mr-1"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              />
              <span class="align-middle">Save Changes</span>
            </b-button>
          </li>
        </b-nav>
      </validation-observer>
    </b-form>

    <b-modal
      id="modal-agent-edit-confirm"
      ref="agent-edit-confirm-modal"
      modal-class="status-update-modal agent__customer-modal"
      footer-class="d-flex justify-content-between pt-0 pb-70"
      body-class="reduce-padding-0"
      header-class="pt-50"
      size="508"
      centered
      no-stacking
      no-close-on-backdrop
      @ok="submitForm"
      @cancel="closeConfirmationModal"
    >
      <template #modal-header>
        <div class="stock-items-modal-header">
          <img
            :src="flyIcon"
            alt="Fly Icon"
          >
          <div class="stock-in-item-modal-heading-block">
            <h4 style="font-weight: 500; font-size: 21px; color: #000000; margin-top: 13px; margin-bottom: 13px;">
              Ready to Submit?
            </h4>
            <p style="color: #333333; margin-bottom: 20px;">
              Please be aware that any changes made will be visible to customers. Please ensure that all modifications are accurate and necessary.
            </p>
          </div>
        </div>
      </template>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="outline-success"
          class="flex-1"
          @click="cancel()"
        >
          Back to editing
        </b-button>
        <b-button
          variant="success-custom"
          class="flex-1"
          type="button"
          @click="ok()"
        >
          <feather-icon
            icon="CheckCircleIcon"
            class="mr-50"
            size="16"
          />
          <span class="align-middle">Submit</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BFormTextarea, BForm, BButton, BNav,
  VBTooltip, BMedia, BAvatar, BFormFile,
} from 'bootstrap-vue'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { required } from '@validations'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { heightTransition } from '@core/mixins/ui/transition'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    BButton,
    BNav,
    BMedia,
    BAvatar,
    BFormFile,

    vueDropzone: vue2Dropzone,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
  },
  directives: { 'b-tooltip': VBTooltip, Ripple },
  mixins: [heightTransition],
  data() {
    return {
    // eslint-disable-next-line global-require
      userAvatar: require('@/assets/images/avatars/2.png'),
      canViewThisAction,
      savedData: false,
      agent: {},
      // agent details
      avatar: null,
      avatarSrc: '',
      agentName: '',
      agentNameError: 'Valid name is required',
      agentNameValidation: false,
      cardDisplayName: '',
      cardDisplayNameError: 'Valid name is required',
      cardDisplayNameValidation: false,
      agency: '',
      agencyError: 'Valid agency is required',
      agencyValidation: false,
      remarks: '',
      remarksError: 'Valid remarks is required',
      remarksValidation: false,
      countryCode: '',
      countryCodeError: 'Valid country code is required',
      countryCodeValidation: false,
      stateCode: '',
      stateCodeError: 'Valid state code is required',
      stateCodeValidation: false,
      agentCode: '',
      agentCodeError: 'Valid value is required',
      agentCodeValidation: false,
      joinDate: '',
      joinDateError: 'Valid date is required',
      joinDateValidation: false,
      confirmDate: '',
      confirmDateError: 'Valid date is required',
      confirmDateValidation: false,
      expiryDate: '',
      expiryDateError: 'Valid date is required',
      expiryDateValidation: false,
      status: 'active',
      statusError: 'Valid value is required',
      statusValidation: false,
      // personal information
      NRIC: '',
      NRICError: 'Valid NRIC is required',
      NRICValidation: false,
      sex: 'male',
      sexError: 'Valid value is required',
      sexValidation: false,
      race: '',
      raceError: 'Valid race is required',
      raceValidation: false,
      marital: 'married',
      maritalError: 'Valid value is required',
      maritalValidation: false,
      dob: '',
      dobError: 'Valid date is required',
      dobValidation: false,
      // correspondence contact
      primaryContact: '',
      primaryContactError: 'Valid primary contact is required',
      primaryContactValidation: false,
      secondaryContact: '',
      secondaryContactError: 'Valid secondary contact is required',
      secondaryContactValidation: false,
      emailAddress: '',
      emailAddressError: 'Valid email address is required',
      emailAddressValidation: false,
      address1: '',
      address1Error: 'Valid address 1 is required',
      address1Validation: false,
      address2: '',
      address2Error: 'Valid address 2 is required',
      address2Validation: false,
      address3: '',
      address3Error: 'Valid address 3 is required',
      address3Validation: false,
      postCode: '',
      postCodeError: 'Valid post code is required',
      postCodeValidation: false,
      // emergency contact
      eContactPerson: '',
      eContactPersonError: 'Valid emergency contact person is required',
      eContactPersonValidation: false,
      mobileNo: '',
      mobileNoError: 'Valid mobile no is required',
      mobileNoValidation: false,
      relationship: '',
      relationshipError: 'Valid value is required',
      relationshipValidation: false,
      // bank details
      bankCode: '',
      bankCodeError: 'Valid bank code is required',
      bankCodeValidation: false,
      bankBranch: '',
      bankBranchError: 'Valid bank branch is required',
      bankBranchValidation: false,
      bankAccountNo: '',
      bankAccountNoError: 'Valid bank account no is required',
      bankAccountNoValidation: false,
      // eslint-disable-next-line global-require
      flyIcon: require('@/assets/images/icons/fly.png'),

      attachments: [],
      dropzoneOptions: {
        autoProcessQueue: false,
        url: 'https://httpbin.org/post',
        acceptedFiles: 'image/jpeg,image/png,application/pdf',
        maxFilesize: 5,
        addRemoveLinks: true,
        dictFileTooBig: 'File is too big ({{filesize}}mb). Max filesize: {{maxFilesize}}mb.',
        // maxFiles: 1,
      },
      // validation rules
      required,
    }
  },

  created() {
    window.addEventListener('resize', this.initTrHeight)
    this.$http.get(`customer/agents/${this.$route.params.id}`)
      .then(response => {
        this.agent = response.data.data
        const agentDetail = response.data.data

        this.agentName = agentDetail.name
        this.cardDisplayName = agentDetail.displayName || ''
        this.agency = agentDetail.agencyName || ''
        this.remarks = agentDetail.remark || ''
        this.countryCode = agentDetail.country || ''
        this.stateCode = agentDetail.state || ''
        this.agentCode = agentDetail.code || ''
        this.joinDate = agentDetail.joinDate ? this.dateFormat(agentDetail.joinDate) : ''
        this.confirmDate = agentDetail.confirmDate ? this.dateFormat(agentDetail.confirmDate) : ''
        this.expiryDate = agentDetail.expDate ? this.dateFormat(agentDetail.expDate) : ''
        this.status = agentDetail.status || ''
        this.NRIC = agentDetail.nricNew || ''
        this.sex = agentDetail.sex || ''
        this.race = agentDetail.race || ''
        this.marital = agentDetail.maritalStatus || ''
        this.dob = agentDetail.dob ? this.dateFormat(agentDetail.dob) : ''
        this.primaryContact = agentDetail.personalContact || ''
        this.secondaryContact = agentDetail.homeContact || ''
        this.emailAddress = agentDetail.email || ''
        this.address1 = agentDetail.address1 || ''
        this.address2 = agentDetail.address2 || ''
        this.address3 = agentDetail.address3 || ''
        this.postCode = agentDetail.postCode || ''
        this.eContactPerson = agentDetail.emergencyContactName || ''
        this.mobileNo = agentDetail.emergencyContactMobile || ''
        this.relationship = agentDetail.emergencyContactRelation || ''
        this.bankCode = agentDetail.bankCode || ''
        this.bankBranch = agentDetail.bankBranch || ''
        this.bankAccountNo = agentDetail.bankAccountNo || ''
        this.avatarSrc = agentDetail.image

        document.title = `Editing ${agentDetail.code} - ${agentDetail.name} | ${process.env.VUE_APP_APP_NAME || 'Nirvana Memorial Garden'}`

        const bcLength = store.state.breadcrumb.breadcrumbs.length
        if (bcLength > 1) {
          store.commit('breadcrumb/REMOVE_BREADCRUMB')
        }
        const newMeta = {
          active: true,
          text: `${agentDetail.code} - ${agentDetail.name}`,
        }
        store.commit('breadcrumb/UPDATE_BREADCRUMB', newMeta)

        agentDetail.attachments.forEach(arrayItem => {
          const file = {
            size: (arrayItem.size * 1024 * 1024), name: arrayItem.name, type: arrayItem.type, description: arrayItem.description,
          }
          this.$refs.myVueDropzone.manuallyAddFile(file, arrayItem.data)
          this.attachments.push(arrayItem)
        })

        const { section } = this.$route.query
        if (section) {
          const element = this.$refs[section]
          if (element) {
            const top = element.offsetTop - 135
            window.scrollTo({
              top,
              behavior: 'smooth',
            })
          }
        }
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },

  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  beforeRouteLeave(to, from, next) {
    if (!this.savedData) {
      this.$swal({
        title: 'Unsaved Changes - Leave?',
        html: 'You have unsaved changes. Are you sure you want to leave this page?',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        imageWidth: 80,
        imageHeight: 80,
        imageAlt: 'Custom Icon',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: "Yes, I'm Sure",
        cancelButtonText: 'No, Go Back',
        customClass: {
          confirmButton: 'btn btn-outline-primary no-focus-bg ml-1',
          cancelButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            next()
          } else {
            next(false)
          }
        })
    } else {
      next()
    }
  },
  methods: {
    setImage() {
      if (this.avatar && this.avatar.size <= 800000) {
        const reader = new FileReader()
        reader.readAsDataURL(this.avatar)
        reader.onload = () => {
          this.avatarSrc = reader.result
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Please select a smaller image',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },
    cancelEdit() {
      this.$swal({
        title: 'Are you sure?',
        html: 'Selecting Cancel will <strong>undo any changes</strong> you have made. This action cannot be undone.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        imageWidth: 80,
        imageHeight: 80,
        imageAlt: 'Custom Icon',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: 'Yes, Cancel!',
        cancelButtonText: 'No, Go Back',
        customClass: {
          confirmButton: 'btn btn-danger ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.$router.push({ name: 'agency-agents-show', params: { id: this.$route.params.id } })
          }
        })
    },
    processFile(file) {
      const acceptedTypes = ['image/jpeg', 'image/png', 'application/pdf']
      file.forEach(element => {
        if (acceptedTypes.includes(element.type)) {
          if (element.size <= 5 * 1024 * 1024) {
            const exist = this.attachments.find(o => o.name === element.name && o.size === (element.size / (1024 * 1024)).toFixed(2))
            if (exist) {
              setTimeout(() => {
                if (element.previewElement) {
                  element.previewElement.remove()
                }
              }, 500)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Duplicate File',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            } else {
              const reader = new FileReader()
              reader.readAsDataURL(element)

              reader.onload = event => {
                const fileObj = {}
                fileObj.name = element.name
                fileObj.description = ''
                fileObj.data = event.target.result
                fileObj.size = (element.size / (1024 * 1024)).toFixed(2)
                fileObj.type = element.type
                this.attachments.push(fileObj)
              }
            }
          }
        }
      })
    },
    fileRemoved(file) {
      if (file.dataURL) {
        this.attachments = this.attachments.filter(element => element.data !== file.dataURL)
      } else {
        this.attachments = this.attachments.filter(element => element.name !== file.name)
      }

      const previewLength = document.getElementsByClassName('dz-preview').length
      if (!previewLength) {
        this.$refs.myVueDropzone.removeAllFiles()
      }
    },
    handleError(file, message) {
      this.$refs.myVueDropzone.removeFile(file)
      if (message !== 'Upload canceled.') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },
    submitAgentSaveForm() {
      this.$root.$emit('bv::show::modal', 'modal-agent-edit-confirm', '')
    },
    closeConfirmationModal() {
      this.$root.$emit('bv::hide::modal', 'modal-agent-edit-confirm', '')
    },
    submitForm() {
      this.$refs.agentEditForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('image', this.avatarSrc ? this.avatarSrc : this.agent.image)
          formData.append('attachments', JSON.stringify(this.attachments))

          this.$http.patch(`customer/agents/${this.$route.params.id}/update`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              if (response.data.data) {
                this.$swal({
                  title: 'Agent Updated',
                  html: response.data.message || '',
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/save.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: true,
                  cancelButtonText: 'View Agent',
                  allowOutsideClick: false,
                  confirmButtonText: 'Return to Agent',
                  customClass: {
                    confirmButton: 'btn btn-outline-primary mr-1',
                    cancelButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
                  .then(result => {
                    this.savedData = true
                    if (result.value) {
                      this.$router.push({ name: 'agency-agents-index' })
                    } else {
                      this.$router.push({ name: 'agency-agents-show', params: { id: response.data.data._id } })
                    }
                  })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message || '',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
            .catch(error => {
              if (error.data.errors) {
                const items = document.getElementsByClassName('text-danger')
                window.scrollTo({
                  top: items[0].offsetTop,
                  behavior: 'smooth',
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
  },
}
</script>

  <style scoped>
    .btn-group-toggle [class*=btn-outline-]:not(:last-child) {
      border-right-width: 1px !important;
    }
    .dropzone-custom-content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
    }
    .dropzone-custom-title {
      margin-top: 0;
      color: #104D9D;
    }
    .subtitle {
      color: #333333;
    }
    .vs__dropdown-option--disabled {
      background: #ededed;
      color: #000000;
    }
    .sd-title {
      font-weight: 500;
      font-size: 15px;
      line-height: 24px;
    }
   .agent__media .media-body{
        display: flex;
        flex-direction: column;
        align-self: center;
   }

  </style>
